import "./BookPageLeftArea.scss";

import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import AudioButton from "components/elements/AudioButton";
import Controller from "components/elements/Controller";
import { ReactComponent as DarkMode } from "../../assets/v2/moon.svg";
import { ReactComponent as IconFullScreen } from "../../assets/icon_fullScreen.svg";
import { IoMenu } from "react-icons/io5"
import { ReactComponent as LightMode } from "../../assets/v2/sun.svg";
import { Link } from "react-router-dom"
import { ReactComponent as Play } from "../../assets/button_play.svg";
import { ReactComponent as Stop } from "../../assets/button_stop.svg";
import { TiHome } from "react-icons/ti"
import SVG from "components/elements/SVG";

const BookPageLeftArea = ((props: any) => {
	const { playlistPlaying } = props;

	return (
		<>
			<div className="ib-bookpage-leftarea-infos">
				<div className="ib-bookpage-leftarea-infos__chapter">
					<SVG src={props.bookPage?.thumbnailsPage} />
					<p>{props.bookPage?.name}</p>
				</div>
				<Controller className="left">
					<Arrow
						onClick={() => {
							props.previousPage();
						}}
						className={
							props.pageIndex > 0
								? "controller--prev-btn"
								: "controller--prev-btn controller--btn-disabled"
						}
					/>
					{!props.isAudioPlaying && !playlistPlaying ? (
						<Play className="controller--play-btn" onClick={() => props.startPlayAll()} />
					) : (
						<Stop className="controller--play-btn" onClick={() => props.stopCurrentAudio()} />
					)}
					<Arrow
						onClick={() => {
							props.nextPage(props.pageIndex);
						}}
						className={
							props.indexBook &&
								props.pageIndex <
								props.indexBook.page.filter((p: any) => !p.soon).length - 1 &&
								!props.bookPage?.soon
								? "controller--next-btn"
								: "controller--next-btn controller--btn-disabled"
						}
					/>
				</Controller>
				<div className="ib-bookpage-leftarea-infos__audios">
					<div
						className={
							props.playlistPlaying
								? "ib-bookpage-leftarea-infos__audios--left ib-bookpage-leftarea-infos__audios--disabled"
								: "ib-bookpage-leftarea-infos__audios--left"
						}
					>
						<AudioButton
							audioKey={props.bookPage?.topAudio ? props.bookPage.topAudio : ""}
							playing={
								props.isAudioPlaying &&
								props.playingAudioRef &&
								props.audiosRef[0].current &&
								props.playingAudioRef.id === props.audiosRef[0].current?.id
							}
							ref={props.audiosRef[0]}
						/>
					</div>
					<div
						className={
							props.playlistPlaying
								? "ib-bookpage-leftarea-infos__audios--right ib-bookpage-leftarea-infos__audios--disabled"
								: "ib-bookpage-leftarea-infos__audios--right"
						}
					>
						<AudioButton
							audioKey={props.bookPage?.bottomAudio ? props.bookPage.bottomAudio : ""}
							playing={
								props.isAudioPlaying &&
								props.playingAudioRef &&
								props.audiosRef[1].current &&
								props.playingAudioRef.id === props.audiosRef[1].current?.id
							}
							ref={props.audiosRef[1]}
						/>
					</div>
				</div>
				<div className="ib-bookpage-leftarea-infos__theme">
					<LightMode
						onClick={props.switchTheme}
						className={
							props.theme
								? "ib-bookpage-leftarea-infos__theme--on"
								: "ib-bookpage-leftarea-infos__theme--off "
						}
					/>
					<DarkMode
						onClick={props.switchTheme}
						className={
							!props.theme
								? "ib-bookpage-leftarea-infos__theme--on"
								: "ib-bookpage-leftarea-infos__theme--off "
						}
					/>
				</div>
			</div>
			<div className="ib-bookpage-leftarea-buttons">
				<div onClick={props.toggleFullScreen} className="ib-bookpage-leftarea-buttons__btn">
					<IconFullScreen onClick={props.toggleFullScreen} className="icon-fullscreen" /><p>Plein Écran</p>
				</div>
				<div className="ib-bookpage-leftarea-buttons__btn">
					<IoMenu className="icon-chapters" /><Link to={`/index-book/${props.indexBook?.id}`}>Chapitres</Link>
				</div>
				<div className="ib-bookpage-leftarea-buttons__btn">
					<TiHome className="icon-home" /><Link to="/">Accueil</Link>
				</div>
			</div>
		</>
	);
});

export default BookPageLeftArea;
