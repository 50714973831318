import "./HomeLeftArea.scss";
import { ReactComponent as MiniGuideIcon } from "../../assets/v2/ib-home-left-logo.svg";
import HooPowAppMenu from "components/elements/common/internalrouter/HooPowAppMenuV2";

const HomeLeftArea = () => {

	return (
		<>
			<div className="ib-home-icon"> <MiniGuideIcon /> </div>
			<HooPowAppMenu />
		</>
	);
};

export default HomeLeftArea;
