import { TBook, TPage } from "types";
import React, { createRef, useCallback, useContext, useEffect, useRef, useState } from "react";

import { AudioPlayerContext } from "./utils/AudioPlayerContext";
import BookPageLeftArea from "./layout/BookPageLeftArea";
import BookPageV2 from "./V2/BookPageV2";
import LayoutV2 from "./layout/LayoutV2";
import { ThemeContext } from "./utils/ThemeContext";
import { useHistory } from "react-router-dom";
import BaseDS from "datastore/BaseDS";
import { useGA4React } from "ga-4-react";

const BookPage = (props: any) => {
	const ga: any = useGA4React();
	const currentIndexBookId = props.match.params.bookId;
	const [bookPage, setBookPage] = useState<TPage>();
	const [indexBook, setIndexBook] = useState<TBook>();
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const { switchTheme, theme } = useContext(ThemeContext);
	const { playlistPlaying, playAudios, stopCurrentAudio, isAudioPlaying, playingAudioRef, setPlaylistPlaying } =
		useContext(AudioPlayerContext);
	const audiosRef = useRef<React.RefObject<HTMLAudioElement>[]>([createRef<HTMLAudioElement>(), createRef<HTMLAudioElement>()]);
	const history = useHistory();
	const pageNumber: number = parseInt(props.match.params.pageNumber);

	const toggleFullScreen = () => {
		stopCurrentAudio();
		setIsFullScreen(!isFullScreen);
	};

	const updatePageUrl = useCallback((index: number) => {
		if (index >= 0) {
			history.push(`/index-book/${currentIndexBookId}/${index + 1}`);
		}
	}, [currentIndexBookId, history]);

	useEffect(() => {
		console.log('useEffect load data from api');
		BaseDS.getFullBookById(currentIndexBookId).then((data: TBook) => {
			setIndexBook(data);
		});
	}, [currentIndexBookId, setIndexBook]);

	useEffect(() => {
		if (indexBook && indexBook.page && indexBook.page.length) {
			console.log('useEffect change page data');
			if (pageNumber > 0 && pageNumber <= indexBook.page.length) {
				setPageIndex(pageNumber - 1);
				setBookPage(indexBook.page[pageNumber - 1]);
			} else {
				updatePageUrl(0);
			}
		};
	}, [pageNumber, indexBook, updatePageUrl, setPageIndex, setBookPage]);

	const nextPage = (currentPageIndex: number, autoPlay?: boolean) => {
		if (indexBook) {
			const nextPageIndex = currentPageIndex + 1;
			if (nextPageIndex < indexBook.page.filter(p => !p.soon).length) {
				setPageIndex(nextPageIndex);
				setBookPage(indexBook.page[nextPageIndex]);
				updatePageUrl(nextPageIndex);
				if (autoPlay) {
					setTimeout(() => playAudios(audiosRef.current, () => {
						nextPage(nextPageIndex, true);
					}, 1000));
				} else {
					stopCurrentAudio();
				}
			} else {
				setPlaylistPlaying(false);
				stopCurrentAudio();
			}
		}
	};

	const previousPage = () => {
		if (pageIndex - 1 >= 0 && indexBook) {
			setPageIndex(pageIndex - 1);
			setBookPage(indexBook.page[pageIndex - 1]);
			updatePageUrl(pageIndex - 1);
			stopCurrentAudio();
		}
	};

	const startPlayAll = () => {
		setPlaylistPlaying(true);
		playAudios(audiosRef.current);
	}

	useEffect(() => {
		if (props.indexBook && ga) {
			const title = document.title.split(" - ")[0] + " - " + props.indexBook.name;
			if (title !== document.title) {
				document.title = document.title.split(" - ")[0] + " - " + props.indexBook.name;
				ga.pageview(document.location.pathname, undefined, document.title);
			}
		}
	}, [props.indexBook, ga]);

	useEffect(() => {
		if (ga && props.indexBook && props.pageIndex === props.indexBook.page.length - 1) {
			ga.event("guides", "End of book " + props.indexBook.name, "engagement");
		}
	}, [props.indexBook, props.pageIndex, ga]);

	return (
		<>
			<LayoutV2
				isFullScreen={isFullScreen}
				leftArea={
					<BookPageLeftArea
						toggleFullScreen={toggleFullScreen}
						isFullScreen={isFullScreen}
						indexBook={indexBook}
						audiosRef={audiosRef.current}
						currentIndexBookId={currentIndexBookId}
						bookPage={bookPage}
						pageIndex={pageIndex}
						switchTheme={switchTheme}
						theme={theme}
						stopCurrentAudio={stopCurrentAudio}
						startPlayAll={startPlayAll}
						playlistPlaying={playlistPlaying}
						isAudioPlaying={isAudioPlaying}
						playingAudioRef={playingAudioRef}
						nextPage={nextPage}
						previousPage={previousPage}
						{...props}
					/>
				}
			>
				<BookPageV2
					toggleFullScreen={toggleFullScreen}
					isFullScreen={isFullScreen}
					audiosRef={audiosRef.current}
					currentIndexBookId={currentIndexBookId}
					pageIndex={pageIndex}
					setPageIndex={setPageIndex}
					indexBook={indexBook}
					setIndexBook={setIndexBook}
					stopCurrentAudio={stopCurrentAudio}
					bookPage={bookPage}
					switchTheme={switchTheme}
					theme={theme}
					playlistPlaying={playlistPlaying}
					startPlayAll={startPlayAll}
					isAudioPlaying={isAudioPlaying}
					playingAudioRef={playingAudioRef}
					nextPage={nextPage}
					previousPage={previousPage}
					{...props}
				/>
			</LayoutV2>
		</>
	);
};
export default BookPage;
