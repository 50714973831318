import ApiWrapper from './ApiWrapper';
import { TBook, TPage } from '../types'

const apiName = 'ApiGatewayRestApi';

export default class BaseDS {

  static getAllBooks = async (): Promise<TBook[]> => {
    return ApiWrapper.get(apiName, '/book/all');
  };

  static getBookById = async (bookId: string): Promise<TBook> => {
    return ApiWrapper.get(apiName, `/book/getById/${bookId}`);
  };

  static getFullBookById = async (bookId: string): Promise<TBook> => {
    return ApiWrapper.get(apiName, `/book/getFullObjectById/${bookId}`);
  };

  static getFullPageById = async (bookId: string): Promise<TPage> => {
    return ApiWrapper.get(apiName, `/book/getFullObjectById/${bookId}`);
  };

}

