import { useState } from "react";
import { TBook } from "types";
import IndexBookV2 from "./V2/IndexBookV2";
import LayoutV2 from "./layout/LayoutV2";
import IndexBookLeftArea from "./layout/IndexBookLeftArea";
import usePortrait from "./utils/usePortrait";
import IndexBookV2Mobile from "./V2/IndexBookV2Mobile";


interface PageIndex {
	pageIndex: number;
	pageOffset: number;
	currentOffset: number;
}

const IndexBook = (props: any) => {
	const [indexBook, setIndexBook] = useState<TBook>();
	const [page, setPage] = useState<PageIndex>({
		pageIndex: 0,
		pageOffset: 15,
		currentOffset: 0,
	});
	const { isPortrait } = usePortrait();

	return (
		<>
			<LayoutV2
				leftArea={<IndexBookLeftArea indexBook={indexBook} setIndexBook={setIndexBook} />}
			>
				{isPortrait ? (
					<IndexBookV2Mobile indexBook={indexBook} setIndexBook={setIndexBook} {...props} />
				) : (
					<IndexBookV2 page={page} setPage={setPage} indexBook={indexBook} setIndexBook={setIndexBook} {...props} />
				)}
			</LayoutV2>
		</>
	);
};
export default IndexBook;
