import {
	forwardRef,
	useEffect,
	useState,
} from "react";

import FileDS from "datastore/FileDS";

const AudioHtmlComponent = forwardRef(
	(
		props: any,
		ref: any
	) => {
		const [fileURL, setFileURL] = useState("");

		const id = Math.random().toString().substr(2,8);

		useEffect(() => {
			if (props.audioKey)  {
				FileDS.getFileUrl(
					props.audioKey,
					setFileURL
				);
			} else {
				setFileURL("");
			}
		}, [props.audioKey]);

		
		return (
			<>
				<audio
					id={id}
					src={fileURL}
					ref={ref}
					onClick={props.onClick}
				/>
			</>
		);
	}
);

export default AudioHtmlComponent;
