import "../css/V2/HomepageV2.scss";

import React, { useContext, useEffect, useState } from "react";

import BaseDS from "../../datastore/BaseDS";
import ImageComponent from "../elements/ImageComponent";
import { Link } from "react-router-dom";
import SVG from "../elements/SVG";
import { TBook } from "types";
import { BlockerContext } from "components/utils/BlockerContext";
import { UserContext } from "components/utils/UserContext";
import { ReactComponent as IconLock } from "../../assets/v3/icon-lock.svg";
import { MainContext } from "components/utils/MainContext";

function HomepageV2() {
	const { allData, setAllData } = useContext(MainContext);
	const [selectedBookId, setSelectedBookId] = useState<string | null>(null);
	const { setBlock, setItemId } = useContext(BlockerContext);
	const { userHasAccess } = useContext(UserContext);

	document.title = document.title.split(' - ')[0] + ' - Accueil';
	useEffect(() => {
		BaseDS.getAllBooks().then((data: any) => {
			setAllData(data);
			let selectedBook = data && data.length && data[0].id;
			const freemiumList = data.length && data.filter((i: any) => i.freemium || userHasAccess(i.id));
			if (freemiumList && freemiumList.length) {
				selectedBook = freemiumList[0].id
			}
			setSelectedBookId(selectedBook);
		});
	}, [userHasAccess, setAllData]);

	const selectBook = (bookId: string) => {
		setSelectedBookId(bookId);
	};

	return (
		<>
			<div className="ib-background"></div>
			<div className="ib-bd-sidebar">
				<div className="ib-bd-sidebar__books-library">
					{allData.map((item: TBook, index: number) => {
						return (<div key={index + "_books"} className={"ib-bd-sidebar__books-library--item" + (!userHasAccess(item.id) && !item.freemium && ' ib-bd-sidebar__books-library--blocked')}
							onClick={(event) => {
								if (!userHasAccess(item.id) && !item.freemium) {
									event.preventDefault();
									setItemId(item.id);
									setBlock(true);
									return false;
								} else if (!item.notAllow) selectBook(item.id)
							}}>
							<SVG
								className={'ib-bd-sidebar__books-library--thumbnail ' +
									(selectedBookId === item.id
										? "ib-bd-sidebar__books-library--active"
										: "") + (item.notAllow
											? " ib-bd-sidebar__books-library--disabled"
											: "")
								}
								key={index}
								src={item.thumbnailsImage}
							/>
							<div className={"overlay " + (selectedBookId === item.id && " selected")}><IconLock /></div>
						</div>);
					})}
				</div>
			</div>
			<div className="ib-bd">
				{allData.map((item: TBook, index: number) => {
					if (
						item.id === selectedBookId
					) {
						return (
							<Link
								to={`/index-book/${item.id}`}
								className="ib-bd__item-container"
								key={index}
								onClick={(event) => {
									if (!userHasAccess(item.id) && !item.freemium) {
										event.preventDefault();
										setItemId(item.id);
										setBlock(true);
										return false;
									}
								}}
							>
								<div className="ib-bd__item-container__cover-box">
									<ImageComponent
										imageKey={item.imageHomepage}
									/>
								</div>
								<button>Lire</button>
							</Link>
						);
					} else return <React.Fragment key={index}></React.Fragment>;
				})}
			</div>
		</>
	);
}

export default HomepageV2;
