import {
	forwardRef,
	useContext
} from "react";

import AudioHtmlComponent from "./AudioHtmlComponent";
import { AudioPlayerContext } from "components/utils/AudioPlayerContext";
import { BeatLoader } from "react-spinners";
import { ReactComponent as IconAudioExo } from "../../assets/icon_audio_exo.svg";

const AudioButton = forwardRef(
	(props: { audioKey: string, disabled?: boolean, playing?: boolean }, ref: any) => {
		const { toggleAudio } = useContext(AudioPlayerContext);

		const toggleAudioButton = () => {
			console.log('AudioButton-ref', ref);
			if (ref && ref.current) {
				toggleAudio(ref.current);
			}
		};


		return (<>
			{ref && (
				<>
					{ref.current?.paused ? (
						<IconAudioExo
							className="ib-clickable ib-audioBtn"
							onClick={toggleAudioButton}

						/>
					) : (
						<div
							onClick={toggleAudioButton}
							className="ib-audio-playing"
						>
							<BeatLoader />
						</div>
					)}
				</>)}
			<AudioHtmlComponent
				ref={ref}
				audioKey={props.audioKey}
			/>
		</>);
	}
);

export default AudioButton;
