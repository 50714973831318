import "../css/V2/BookPageV2.scss";

import { useContext, useEffect, useState } from "react";

import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import AudioButton from "../elements/AudioButton";
import Controller from "components/elements/Controller";
import { ReactComponent as DarkMode } from "../../assets/moon.svg";
import { ReactComponent as IconReduceScreen } from "../../assets/icon_reduceScreen.svg";
import ImageComponent from "../elements/ImageComponent";
import { ReactComponent as LightMode } from "../../assets/sun.svg";
import { ReactComponent as Play } from "../../assets/button_play.svg";
import SVG from "../elements/SVG";
import { ReactComponent as Stop } from "../../assets/button_stop.svg";
import { ReactComponent as Support } from "../../assets/light_bg_book.svg";
import { TiHome } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import { UserContext } from "components/utils/UserContext";

const BookPageV2 = (props: any) => {
	const history = useHistory();
	const { previousPage, playlistPlaying, location, bookPage, isFullScreen, theme, switchTheme } = props;
	const { pageIndex, isAudioPlaying, nextPage, indexBook, toggleFullScreen } = props;
	const { audiosRef, playingAudioRef, currentIndexBookId } = props;
	const [passedState] = useState<any>(location ? location.state : "none");
	const { isUserAuthenticated } = useContext(UserContext);

	useEffect(() => {
		if(indexBook && !isUserAuthenticated && !indexBook.freemium) {
			document.location.href = '/';
		}
	}, [indexBook, isUserAuthenticated]);
	return (
		<div id="ibBookImagesContent" className={`standalone`}>
			<Support className="bg-support" />
			<div className="ib-book-page">
				<div className="ib-book-page__image">
					<ImageComponent imageKey={bookPage?.bookPage} />
				</div>
				<SVG className="ib-book-page__text" src={bookPage?.bookTextSVG} />
			</div>

			{isFullScreen && (
				<>
					<Controller className="main">
						{theme ? (
							<LightMode
								onClick={switchTheme}
								className="controller--main-theme-icon"
							/>
						) : (
							<DarkMode
								onClick={switchTheme}
								className="controller--main-theme-icon"
							/>
						)}
						<div className="controller--separator"></div>
						<Arrow
							onClick={() => {
								previousPage();
							}}
							className={
								pageIndex > 0
									? "controller--prev-btn"
									: "controller--prev-btn controller--btn-disabled"
							}
						/>
						{!isAudioPlaying && !playlistPlaying ? (
							<Play
								className="controller--play-btn"
								onClick={() => props.startPlayAll()}
							/>
						) : (
							<Stop
								className="controller--play-btn"
								onClick={() => props.stopCurrentAudio()}
							/>
						)}

						<Arrow
							onClick={() => {
								nextPage(pageIndex);
							}}
							className={
								indexBook &&
									pageIndex <
									indexBook.page.filter((p: any) => !p.soon).length - 1 &&
									!bookPage?.soon
									? "controller--next-btn "
									: "controller--next-btn controller--btn-disabled"
							}
						/>
						<div className="controller--separator"></div>
						<IconReduceScreen
							className="controller--reduce-btn"
							onClick={toggleFullScreen}
						/>
					</Controller>
					<div className="controller__dark-container">
						<TiHome
							className="controller__dark-container--exit-btn"
							onClick={() => {
								history.push(
									passedState && passedState.backPath
										? passedState.backPath
										: `/preface-book/${currentIndexBookId}`
								);
							}}
						/>
					</div>
					<div className="ib-book-page-audio">
						<div
							className={
								playlistPlaying
									? "ib-book-page-audio__top disable-audio"
									: "ib-book-page-audio__top"
							}
						>
							<AudioButton
								audioKey={bookPage?.topAudio ? bookPage.topAudio : ""}
								playing={
									isAudioPlaying &&
									playingAudioRef &&
									audiosRef[0].current &&
									playingAudioRef.id === audiosRef[0].current?.id
								}
								ref={audiosRef[0]}
							/>
						</div>
						<div
							className={
								playlistPlaying
									? "ib-book-page-audio__bottom disable-audio"
									: "ib-book-page-audio__bottom"
							}
						>
							<AudioButton
								audioKey={bookPage?.bottomAudio ? bookPage.bottomAudio : ""}
								playing={
									isAudioPlaying &&
									playingAudioRef &&
									audiosRef[1].current &&
									playingAudioRef.id === audiosRef[1].current?.id
								}
								ref={audiosRef[1]}
							/>
						</div>
					</div>
				</>
			)}

			<p className="page-index">- {(pageIndex + 1).toString()} - </p>
		</div>
	);
};

export default BookPageV2;
