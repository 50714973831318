import "../css/V2/IndexBookV2.scss";
import { TBook, TPage } from "types";
import { useEffect } from "react";
import BaseDS from "../../datastore/BaseDS";
import ImageDataStore from "datastore/ImageDataStore";
import ImageComponent from "components/elements/ImageComponent";
import { Button } from "components/elements/Button";
import { TiHome } from "react-icons/ti";
import { Link } from "react-router-dom";

const IndexBookV2Mobile = (props: any) => {
	const currentIndexBookId = props.match?.params.bookId;
	const {setIndexBook} = props;

	const addImageUrlToData = (book: TPage) => {
		return new Promise((resolve) => {
			ImageDataStore.getUrlImage(book.thumbnailsPage || "", (url: string) => {
				book.imageUrl = url;
				resolve(url);
			});
		});
	};
	useEffect(() => {
		BaseDS.getFullBookById(currentIndexBookId).then((data: TBook) => {
			if (data.page) {
				let promises: any = [];
				data.page.forEach((book) => {
					if (book.thumbnailsPage) {
						promises.push(addImageUrlToData(book));
					}
				});

				Promise.all(promises).then(() => {
					setIndexBook(data);
				});
			} else {
				setIndexBook(data);
			}
		});
	}, [currentIndexBookId, setIndexBook]);

	console.log(props.indexBook);

	return (
		<>
			<div className="ib-index-book-mobile">
				<div className="ib-index-book-mobile__book">
					<ImageComponent
						imageKey={props.indexBook?.imageHomepage}
						alt="couverture du livre"
					/>
					<Button className="blue ib-index-book-mobile__book--btn">
						Lecture Complète
					</Button>
				</div>
				<div className="ib-index-book-mobile__chapters">
					<p className="ib-index-book-mobile__chapters-title">Accès direct aux pages</p>
					<div className="ib-index-book-mobile__chapters-list">
						{props.indexBook?.page.map((chapter: any, index: number) => {
							return (
								<Link
									to={`/index-book/${chapter.bookId}/${index + 1}`}
									key={index}
									className="ib-index-book-mobile__chapters-list--chapter"
								>
									<ImageComponent
										imageSrc={chapter.imageUrl}
										alt="couverture du chapitre"
									/>
									<p> {chapter.name} </p>
								</Link>
							);
						})}
					</div>
				</div>
				<Button className="dark ib-index-book-mobile__book--btn ib-index-book-mobile__book--btn-home ">
					<Link to="/">
						<TiHome className="icon-home" /> Accueil
					</Link>{" "}
				</Button>
			</div>
		</>
	);
};

export default IndexBookV2Mobile;
