import './HooPowAppMenuV2.scss'

import { useEffect, useState } from 'react';

import HooPowDS from './HooPowDS';
import React from 'react';

export const routeToPlatformHomePage = () => {
  const protocol = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? 'https' : 'http';
  const port = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? '' : ':3001';
  return protocol + '://' + process.env.REACT_APP_DOMAIN_NAME + port
}

function HooPowAppMenuV2() {
  const [applications, setApplications] = useState<any[]>();

  useEffect(() => {
    HooPowDS.getPlatformDef().then((platformDef: any) => {
      setApplications(platformDef.application);
    });
  }, []);

  return (
    <div className="hp-menu-icon">
      {applications && (<>
        <a href={routeToPlatformHomePage()} className="hp-menu-icon--link" >
          <span>
            <object type='image/svg+xml' data={routeToPlatformHomePage() + '/icon.svg'} aria-label='HooPow' />
          </span>
        </a>
        {applications.filter((appDef: any) => appDef.publicationDate < new Date().toISOString()).map((appDef: any, index) => {
          return (
            <React.Fragment key={index}>
              <a href={appDef.url} className="hp-menu-icon--link" key={index}>
                <span>
                  <object type='image/svg+xml' data={appDef.url + '/icon.svg'} aria-label={appDef.name} />
                </span>
              </a>
            </React.Fragment>
          );
        })}
      </>)}
    </div>
  );
}

export default HooPowAppMenuV2;
