import React, { createContext, useState } from "react";

export const AudioPlayerContext = createContext<any>("");

const AudioPlayerContextProvider = (props: any) => {
	const [
		playlistPlaying,
		setPlaylistPlaying,
	] = useState<boolean>(false);
	const [playingAudioRef, setPlayingAudioRef] = useState<HTMLAudioElement | null>(null);
	const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);

	// Play the playlist of audios
	const playAudios = (
		audios: React.RefObject<HTMLAudioElement>[],
		callback?: any
	) => {

		if (audios && audios.length) {
			const audiosTemp = [...audios];
			console.log('audiosTemp', audiosTemp)
			const audioElement = audiosTemp[0].current;
			if (audioElement) {
				audioElement.volume = 0.8;
				audioElement.play();

				setPlayingAudioRef(audioElement);
				setIsAudioPlaying(true)

				// Recursion
				audioElement.onended = () => {
					audiosTemp.shift();
					setIsAudioPlaying(false)

					console.log('audios', Object.assign({}, ...audiosTemp));
					if (audiosTemp.length === 0 && callback) {
						console.log('calling back ------', callback);
						callback();
					} else {
						playAudios(audiosTemp, callback);
					}
				};
			}
		} else {
			console.log('ending playlist ------');
			setPlaylistPlaying(false);
			setIsAudioPlaying(false);
			setPlayingAudioRef(null);
		}
	};

	// Pause the playlist of audios
	const pauseAudios = (audios: HTMLAudioElement[]) => {
		console.log('pauseAudios ------');
		if (audios && audios.length) {
			stopCurrentAudio();
			setPlaylistPlaying(false);
			setIsAudioPlaying(false);
		}
	};

	// toggle state audio
	const toggleAudio = (audioRef: HTMLAudioElement) => {
		console.log('toggleAudio ------');
		if (audioRef) {
			if (audioRef.paused) {
				playAudio(audioRef);
			} else {
				stopCurrentAudio();
			}
		}
	};

	// play l'audio
	const playAudio = (audioRef: HTMLAudioElement) => {
		console.log('playAudio ------');
		if (audioRef) {
			if (playingAudioRef && playingAudioRef.id !== audioRef.id) {
				stopCurrentAudio();
			}
			setPlayingAudioRef(audioRef);
			setIsAudioPlaying(true)
			audioRef.play();
			audioRef.onended = () => {
				setPlayingAudioRef(null)
				setIsAudioPlaying(false)
			}

		}
	}

	// Stop current audio playing
	const stopCurrentAudio = () => {
		if (playingAudioRef && !playingAudioRef.paused) {
			playingAudioRef.pause();
			setIsAudioPlaying(false);
			setPlayingAudioRef(null);
			setPlaylistPlaying(false);
			playingAudioRef.currentTime = 0;
		}
	}

	return (
		<AudioPlayerContext.Provider
			value={{ playlistPlaying, setPlaylistPlaying, playAudios, pauseAudios, stopCurrentAudio, toggleAudio, playingAudioRef, isAudioPlaying }}
		>
			{props.children}
		</AudioPlayerContext.Provider>
	);
};

export default AudioPlayerContextProvider;
