import { createContext,  useState } from "react";

export const ThemeContext = createContext<any>("");

const ThemeContextProvider = (props: any) => {
	const [theme, setTheme] = useState<boolean>(true);
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

	const switchTheme = () => {
		setTheme(!theme);
		console.log(theme);
	};
	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	return (
		<ThemeContext.Provider
			value={{ switchTheme, theme, toggleFullScreen, isFullScreen }}
		>
			{props.children}
		</ThemeContext.Provider>
	);
};

export default ThemeContextProvider;
