import "./index.scss";

import * as serviceWorker from "./serviceWorker";

import Amplify from "aws-amplify";
import App from "./App";
import GA4React from 'ga-4-react';
import React from "react";
import ReactDOM from "react-dom";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

(async () => {
	if (process.env.REACT_APP_GA_ID
		&& process.env.REACT_APP_GA_ID !== '') {
		console.log('Initialise google analytics with the following code => ' + process.env.REACT_APP_GA_ID);
		const ga4react = new GA4React(process.env.REACT_APP_GA_ID);
		try {
			await ga4react.initialize();
		} catch (err) {
			console.error(err);
		}
	}
	

	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById("interactiveBook")
	);
})();

serviceWorker.unregister();
