import "./BlockedModal.scss";
import { ReactComponent as IconClose } from "assets/v3/icon-close.svg";
import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import SubscriptionSelector from "./SubscriptionSelector";
import { routeToPlatformHomePage } from "components/utils/InternalRouter";
import { productId, UserContext } from "components/utils/UserContext";
import PaymentHeader from "./PaymentHeader";
import { ClockLoader as Loader } from "react-spinners";
import IFrame from "./IFrame";
import UnblockForFree from "./UnblockForFree";
import { BlockerContext, steps } from "components/utils/BlockerContext";
import { MainContext } from "components/utils/MainContext";
import { TBook } from "types";
import ImageDataStore from "datastore/ImageDataStore";

const extraStyle: steps[] = [steps.SIGNUP, steps.SIGNIN, steps.GIFT, steps.EDITPROFIL, steps.ORDERS, steps.MEMBER, steps.WHO, steps.WHAT, steps.SUPPORTUS, steps.CONTACTUS];

const platformBaseUrl = routeToPlatformHomePage().replace("hoo-pow.com", "www.hoo-pow.com");

function BlockedModal(props: { open: boolean, setOpen: Function, unblockStep: steps, setUnblockStep: Function, nextStep: MutableRefObject<steps> }) {
  const { refreshUserInfo, isUserAuthenticated, userHasAccess } = useContext(UserContext);
  const { allData } = useContext(MainContext);
  const { itemId } = useContext(BlockerContext);
  const { unblockStep, setUnblockStep, nextStep } = props;

  const [selectedItem, setSelectedItem] = useState<any>();

  const isAuthenticatedRef = useRef<boolean>(isUserAuthenticated);

  useEffect(() => {
    if (allData) {
      setSelectedItem(allData.filter((i: TBook) => i.id === itemId)[0])
    }
  }, [allData, itemId])

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin === platformBaseUrl) {
        if (event.data.step === 'signedup' || event.data.step === 'signedin') {
          isAuthenticatedRef.current = true;
          setUnblockStep(steps.LOADING);

          if (process.env.REACT_APP_FEATURE_PAUSE === '1') {
            document.location.reload();
          } else {
            refreshUserInfo(() => {
              if (userHasAccess()) {
                document.location.reload();
              } else {
                setUnblockStep(nextStep.current || steps.SUBSCRIPTION)
              }
            });
          }
        }
        if (event.data.step === 'payment_done_successfully') {
          document.location.href = '/'
        }
        if (event.data.step === 'subscription') {
          setUnblockStep(steps.SUBSCRIPTION_PAYMENT);
        }
        if (event.data.step === 'edition_success') {
          refreshUserInfo();
        }
      }
    });
  }, [refreshUserInfo, userHasAccess, nextStep, setUnblockStep]);

  useEffect(() => {
    isAuthenticatedRef.current = isUserAuthenticated;
  }, [isUserAuthenticated]);

  const viewSelector = useCallback(() => {
    console.log("unblockStep", steps[unblockStep])
    if (unblockStep !== steps.SIGNUP
      && unblockStep !== steps.SIGNIN
      && unblockStep !== steps.SUBSCRIPTION
      && unblockStep !== steps.LOADING
      && !isAuthenticatedRef.current) {
      nextStep.current = unblockStep;
      setUnblockStep(steps.SIGNUP);
      return;
    }
    switch (unblockStep) {
      case steps.RELOAD:
        document.location.reload();
        return (<></>)
      case steps.SIGNIN:
      case steps.SIGNUP:
        return (<>
          <IFrame key='iframe-signin' id='iframe-signin' className="iframe-steps"
            src={`${platformBaseUrl}/embedded/vw/auth?appId=${process.env.REACT_APP_ID}&initialState=${steps[unblockStep]}`}
            title='Signup' />
        </>)
      case steps.EDITPROFIL:
        return (<>
          <IFrame key='iframe-edituser' id='iframe-edituser' className="iframe-steps"
            src={`${platformBaseUrl}/embedded/vw/edituser?appId=${process.env.REACT_APP_ID}`}
            title='Edit User' />
        </>)
      case steps.GIFT:
        return (<>
          <IFrame key='iframe-gift' id='iframe-gift'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/gift`}
            title='Gift' />
        </>)
      case steps.WHO:
        return (<>
          <IFrame key='iframe-who' id='iframe-who'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/v2/who`}
            title='Who' />
        </>)
      case steps.WHAT:
        return (<>
          <IFrame key='iframe-what' id='iframe-what'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/v2/what`}
            title='What' />
        </>)
      case steps.SUPPORTUS:
        return (<>
          <IFrame key='iframe-supportus' id='iframe-supportus'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/v2/supportus`}
            title='Support Us' />
        </>)
      case steps.CONTACTUS:
        return (<>
          <IFrame key='iframe-contactus' id='iframe-contactus'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/v2/contactus`}
            title='Contact Us' />
        </>)
      case steps.ORDERS:
        return (<>
          <IFrame key='iframe-orders' id='iframe-orders'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/orders`}
            title='Gift' />
        </>)
      case steps.ONESHOT_PAYMENT:
        return (<>
          <IFrame key='iframe-payment' id='iframe-payment'
            className="iframe-steps"
            paymentInfo={{
              image: ImageDataStore.getUrl(selectedItem?.thumbnailsImage || "no-data"),
              title: selectedItem?.name,
              description: selectedItem?.purchaseDesc,
              content: selectedItem?.purchaseContent,
              status: selectedItem?.purchaseStatus
            }}
            src={`${platformBaseUrl}/embedded/vw/checkout?appId=${process.env.REACT_APP_ID}&productId=${productId}&itemId=${itemId}`}
            title='Signup' />
        </>)
      case steps.MEMBER:
        return (<>
          <IFrame key='iframe-member' id='iframe-member'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/vw/member?appId=${process.env.REACT_APP_ID}`}
            title='Espace Membre' />
        </>);
      case steps.SUBSCRIPTION_PAYMENT:
        return (<>
          <IFrame key='iframe-subpayment' id='iframe-subpayment'
            className="iframe-steps"
            src={`${platformBaseUrl}/embedded/vw/checkout?appId=${process.env.REACT_APP_ID}&productId=${"monthly_plan_v3"}&itemId=${itemId}`}
            title='Subscription' />
        </>);
      case steps.SHARE:
        return (<>
          <PaymentHeader itemId={itemId} />
          <UnblockForFree
            paymentInfo={{
              image: ImageDataStore.getUrl(selectedItem?.thumbnailsImage || "no-data"),
              title: selectedItem?.name,
              description: selectedItem?.purchaseDesc,
              content: selectedItem?.purchaseContent,
              status: selectedItem?.purchaseStatus
            }}
          />
        </>);
      case steps.LOADING:
        return (
          <div style={{ position: "absolute", bottom: "50px", zIndex: 9 }} id="iframe-loader" className="hideDiv">
            <Loader size={60} color="black" />
          </div>);

      case steps.SUBSCRIPTION:
      default:
        if (process.env.REACT_APP_FEATURE_PAUSE === '1') {
          return (<>
            <IFrame key='iframe-pausesignup' id='iframe-pausesignup'
              className="iframe-steps"
              src={`${platformBaseUrl}/embedded/pausemessage?appId=${process.env.REACT_APP_ID}`}
              title='Pause message' />
          </>)
        } else {
          return (<><PaymentHeader itemId={itemId} /><SubscriptionSelector productId={productId || ""} itemId={itemId} setUnblockStep={setUnblockStep} /></>)
        }
    }
  }, [unblockStep, nextStep, setUnblockStep, itemId, selectedItem]);

  const closeModal = () => {
    props.setOpen(false);
    setUnblockStep(steps.SUBSCRIPTION);
  }

  return (
    <>
      {props.open && (
        <div className={`hp-blockedModal-popup ${process.env.REACT_APP_FEATURE_PAUSE === '1' && 'pause-message'} ${(unblockStep && steps[unblockStep]) || "SUBSCRIPTION"} ${extraStyle.indexOf(unblockStep) > -1 && "extra-step"}`}>
          <div className="hp-blockedModal-popup__wrapper">
            <IconClose className="hp-blockedModal-popup__close ms-clickable" onClick={closeModal} />
            {viewSelector()}
          </div>
        </div>
      )}
    </>);
}

export default BlockedModal;
