import { useState, useEffect } from "react";

const usePortrait = () => {
	const [isPortrait, setIsPortrait] = useState<boolean>();


	useEffect(() => {

		const calculatePortrait = () => {
			if (document.getElementById("interactiveBook")?.classList.contains("portrait")) {
				setIsPortrait(true);
			} else {
				setIsPortrait(false);
			}
		};
		window.addEventListener("load", calculatePortrait);
		window.addEventListener("resize", calculatePortrait);
	}, [isPortrait]);

	return {isPortrait}
};

export default usePortrait;
