import './HooPowAppMenu.scss'

import { useEffect, useState } from 'react';

import HooPowDS from './HooPowDS';
import { ReactComponent as IconHome } from './icon_home.svg';
import { ReactComponent as TopBanner } from './hoopow-topbanner.svg';

export const routeToPlatformHomePage = () => {
  const protocol = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? 'https' : 'http';
  const port = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? '' : ':3001';
  return protocol + '://' + process.env.REACT_APP_DOMAIN_NAME + port
}

function HooPowAppMenu() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [applications, setApplications] = useState<any[]>();
  const hostName = document.location.hostname;

  useEffect(() => {
    HooPowDS.getPlatformDef().then((platformDef: any) => {
      setApplications(platformDef.application);
    });
  }, []);

  return (
    <div className='hp-appTopMenu'>
      <div className='hp-logo hp-clickable' onClick={() => setMenuOpen(prev => !prev)}>
        <TopBanner />
      </div>
      <div className={`hp-subMenu ${menuOpen ? 'hp-subMenuOpen' : 'hp-subMenuClose'}`}>
        <div className='hp-appTopMenu-menuApps'>
          {applications
            && applications.filter((appDef: any) => appDef.publicationDate < new Date().toISOString()).map((appDef: any) => (
              <a key={appDef.name} href={appDef.url}
                className={`hp-appTopMenu-menuApps-app hp-clickable ${appDef.url.indexOf(hostName) > -1 ? ' hp-appTopMenu-menuApps-app-selected' : ''}`}>
                <img src={appDef.url + '/logo192.png'} alt={appDef.name} />
                <span>{appDef.name}</span>
              </a>))}
          <a href={routeToPlatformHomePage()} className='hp-appTopMenu-menuApps-home hp-clickable' >
            <IconHome />ACCUEIL
          </a>
        </div>
      </div>
    </div>
  );
}

export default HooPowAppMenu;
