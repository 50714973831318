import { Auth } from "aws-amplify";
import React, { createContext, useCallback, useState } from "react";

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import HooPowDS from "components/elements/common/internalrouter/HooPowDS";

export const UserContext = createContext<any>("");
export const productId = process.env.REACT_APP_PRODUCT_ID

const UserContextProvider = (props: any) => {
	const [userInfo, setUserInfo] = useState<any>();
	const [userBlocked, setUserBlocked] = useState<any>();
	const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>();
	const [userAccess, setUserAccess] = useState<string[]>();
	const [userSubscriptionDate, setUserSubscriptionDate] = useState<number>(0);
	const freemiumEnabled = process.env.REACT_APP_FEATURE_FREEMIUM === '1';

	const refreshUserInfo = useCallback((callback?: Function) => {

		Auth.currentAuthenticatedUser({ bypassCache: true }).then((data) => {
			setUserInfo(data);
			setIsUserAuthenticated(true);
			// TODO Remove when freemium goes live
			// Start
			if (!freemiumEnabled) {
				const userGroup = data?.signInUserSession.accessToken.payload['cognito:groups'];
				if (!userGroup || !userGroup.length || userGroup.indexOf('blocked') === -1) {
					setUserBlocked(false);
				} else {
					setUserBlocked(true);
				}
			}

			if (process.env.REACT_APP_FEATURE_FREEMIUM === "1") {
				HooPowDS.getUserAccess(productId || "").then((userAccessData) => {
					setUserAccess(userAccessData.access);
					setUserSubscriptionDate(userAccessData.startingDate);
					if (callback) callback();
				})
			} else {
				if (callback) callback();
			}
			// End
		}).catch((error) => {
			console.log('error', error);
			setUserInfo(undefined)
			setIsUserAuthenticated(false);
			setUserBlocked(true);
			setUserAccess([]);
		});
	}, [freemiumEnabled]);

	React.useEffect(() => {
		console.log("useEffect => refreshUserInfo");
		refreshUserInfo();
		return onAuthUIStateChange((nextAuthState) => {
			if (AuthState.SignedIn === nextAuthState) {
				refreshUserInfo();
			} else {
				setUserInfo(undefined);
				setIsUserAuthenticated(false);
			}
		});
	}, [refreshUserInfo]);

	const userHasAccess = useCallback((id?: string) => {
		return (isUserAuthenticated && process.env.REACT_APP_FEATURE_PAUSE === '1') || (freemiumEnabled
			? (userAccess?.includes("full") || userAccess?.includes(id || "no_id")) :
			(userBlocked === false || userBlocked === undefined) && (isUserAuthenticated || isUserAuthenticated === undefined))
	}, [userBlocked, isUserAuthenticated, freemiumEnabled, userAccess]);

	return (
		<UserContext.Provider value={{ userSubscriptionDate, userHasAccess, freemiumEnabled, isUserAuthenticated, userInfo, refreshUserInfo }} >
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContextProvider;
