import HomepageV2 from "./V2/HomepageV2";
import LayoutV2 from "./layout/LayoutV2";
import HomeLeftArea from "./layout/HomeLeftArea"
import { useContext } from "react";
import { UserContext } from "./utils/UserContext";
import HomeLeftAreaV3 from "./elements/common/freemium/HomeLeftArea";

const Homepage = (props: any) => {
	const { freemiumEnabled } = useContext(UserContext);

	return (
		<>
			<LayoutV2 leftArea={freemiumEnabled ? <HomeLeftAreaV3/> : <HomeLeftArea/>} >
				<HomepageV2 {...props} />{" "}
			</LayoutV2>
		</>
	);
};
export default Homepage;
