import "./HomeLeftArea.scss";
import { ReactComponent as MiniGuideIcon } from "assets/v2/ib-home-left-logo.svg";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";

const HomeLeftAreaV3 = () => {

	return (
		<>
			<div className="ms-home-icon-v3"> <MiniGuideIcon /> </div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default HomeLeftAreaV3;
