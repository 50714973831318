import "./Button.scss"

const Button = (props : {children:any, className:any, disabled?:any, onClick?:any}) => {
  return (
    <button onClick={props.onClick} disabled={props.disabled} className={ `btn btn--${props.className}`}>
      {props.children}
    </button>
  )
}

export  {Button}
