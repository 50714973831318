import { useContext, useEffect, useState } from 'react';
import { ReactComponent as HoopowLogo } from '../../assets/hoopow-logo.svg';
import { ReactComponent as IconEmail } from '../../assets/v2/menu_icon_email.svg';
import { ReactComponent as IconTelegram } from '../../assets/v2/menu_icon_telegram.svg';
import { ReactComponent as IconLinkedin } from '../../assets/v2/menu_icon_linkedin.svg';
import './Menu.scss';
import { Auth } from 'aws-amplify';
import { routeToPlatformHomePage } from 'components/utils/InternalRouter';
import { UserContext } from 'components/utils/UserContext';

function Menu(props: { menuOpen?: boolean }) {
	const [menuOpen, setMenuOpen] = useState<boolean | undefined>(props.menuOpen);
	const [userRoot, setRootUser] = useState<any>();

	const { userInfo } = useContext(UserContext);

	const logout = async () => {
		await Auth.signOut();
		window.location.reload();
	}

	useEffect(() => {
		if (userInfo && userInfo.attributes) {
			setRootUser({
				name: userInfo.attributes.name,
				email: userInfo.attributes.email,
			});
		} else {
			setRootUser(undefined);
		}
	}, [userInfo]);

	return (
		<>
			<div className={`hp-menu-header`}>
				<div className={`hp-menu-nav-frame ks-clickable`} onClick={() => setMenuOpen(prev => !prev)} >
					<div className={`hp-menu-nav-icon ${menuOpen && 'open'}`} >
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
				<a href={`${routeToPlatformHomePage()}`}><HoopowLogo className='hp-menu-logo' /></a>
			</div>
			<div className={`hp-menu-frame ${menuOpen && 'open'}`}>
				<div className={`hp-menu-frame-btn hp-menu-frame-member`}>
					{userRoot
						? (<>
							<div className='mainBtn'>{userRoot.name}</div>
							<a href={`${routeToPlatformHomePage()}/member`} className={`mainBtn`}>MON ESPACE MEMBRE</a>
						</>)
						: (
							<>
								<a href={`${routeToPlatformHomePage() + (process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? '/subscribe' : '/member?showPlans=1')}`} className='mainBtn'>S’ABONNER</a>
								<a href={`${routeToPlatformHomePage() + (process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? '/signin' : '/login')}`} className={`mainBtn`}>SE CONNECTER</a>
							</>
						)}
					<a href={`${routeToPlatformHomePage()}/giftspace`} className='mainBtn'>OFFRIR UN ABONNEMENT</a>
				</div>
				<div className={`hp-menu-frame-btn hp-menu-frame-contact`}>
					<a href={`${routeToPlatformHomePage()}/contactus`} className='btnWithIcon'><IconEmail /><span>NOUS<br />CONTACTER</span></a>
					<a href='https://t.me/muslimshow_officiel' className='btnWithIcon'><IconTelegram /><span>NOUS SUIVRE<br />SUR TELEGRAM</span></a>
					<a href='https://www.linkedin.com/company/hoopow/' className='btnWithIcon'><IconLinkedin /><span>NOUS SUIVRE<br />SUR LINKEDIN</span></a>
				</div>
				<div className={`hp-menu-frame-btn hp-menu-frame-member`}>
					<a href={`${routeToPlatformHomePage()}/cguconnected`} className='mainBtn'>CONDITIONS D’UTILISATION</a>
					{userRoot && userRoot.name !== 'Erreur' && (
						<div onClick={logout} className='mainBtn logout'>SE DÉCONNECTER</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Menu;
