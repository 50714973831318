import "./IndexBookLeftArea.scss";
import { ReactComponent as IconAwlad } from "assets/v2/icon-awlad.svg";
import { ReactComponent as IconMuslimShow } from "assets/v2/icon-muslim-show.svg";
import { ReactComponent as IconFoulane } from "assets/v2/icon-foulane.svg";
import { ReactComponent as IconQuiz } from "assets/v2/icon-quiz.svg";
import { ReactComponent as IconGuides } from "assets/v2/icon-guides.svg";
import ImageComponent from "../elements/ImageComponent";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";

export const leftAreaIcons = [
	{
		id: 1,
		url: "#",
		icon: <IconAwlad />,
	},
	{
		id: 2,
		url: "#",
		icon: <IconFoulane />,
	},
	{
		id: 3,
		url: "#",
		icon: <IconMuslimShow />,
	},
	{
		id: 4,
		url: "#",
		icon: <IconQuiz />,
	},
	{
		id: 5,
		url: "#",
		icon: <IconGuides />,
	},
	{
		id: 9,
		url: "#",
		icon: <IconGuides />,
	},
];

const IndexBookLeftArea = (props: any) => {
	return (
		<>
			<div className="ib-indexbook-leftarea">
				<div className="ib-indexbook-leftarea__thumbnails">
				<ImageComponent imageKey={props.indexBook?.imageHomepage} />

				</div>
				<div className="ib-indexbook-leftarea__authors">
					<p>Texte : {props.indexBook?.textAuthor}</p>
					<p>Dessins : {props.indexBook?.drawAuthor}</p>
					<p>Voix : {props.indexBook?.voiceAuthor}</p>
				</div>
			</div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default IndexBookLeftArea;
