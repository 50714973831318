import "../css/V2/IndexBookV2.scss";
import { TBook } from "types";
import { useEffect } from "react";
import BaseDS from "../../datastore/BaseDS";
import { ReactComponent as BookBackground } from "../../assets/v2/ib-book-background.svg";
import { Button } from "components/elements/Button";
import { Link } from "react-router-dom";
import SVG from "../elements/SVG";
import { TiHome } from "react-icons/ti";

const IndexBookV2 = (props: any) => {
	const currentIndexBookId = props.match.params.bookId;
	const { setIndexBook } = props;

	useEffect(() => {
		BaseDS.getFullBookById(currentIndexBookId).then((data: TBook) => {
			setIndexBook(data);
		});
	}, [currentIndexBookId, setIndexBook]);

	useEffect(() => {
		if (props.indexBook) {
			document.title =
				document.title.split(" - ")[0] +
				" - " +
				props.indexBook.name +
				" - Choix de page";
		}
	}, [props.indexBook]);

	const hasNextPage = (): boolean => {
		if (props.indexBook?.page && props.indexBook?.page.length) {
			return (
				props.page.currentOffset + props.page.pageOffset <
				props.indexBook.page.length
			);
		}
		return false;
	};

	const hasPreviousPage = (): boolean => {
		return props.page.pageIndex > 0;
	};

	const changePage = (direction: "prev" | "next"): void => {
		const newPageState = props.page;

		if (direction === "prev" && hasPreviousPage()) {
			newPageState.pageIndex = newPageState.pageIndex - 1;
		} else if (direction === "next" && hasNextPage()) {
			newPageState.pageIndex = newPageState.pageIndex + 1;
		}

		newPageState.currentOffset =
			newPageState.pageOffset * newPageState.pageIndex;
		props.setPage(Object.assign({}, newPageState));
	};

	return (
		<>
			<div
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: "0",
					left: "0",
				}}
			>
				<SVG src={props.indexBook?.specificBackground} />
			</div>
			<BookBackground className="ib-index-book-background" />
			<div className="ib-index-book">
				<div className="ib-index-book__left">
					{/** display 9 items on the left of the props.page  */}
					{props.indexBook?.page
						.slice(0 + props.page.currentOffset, 9 + props.page.currentOffset)
						.map((item: any, index: number) => {
							return (
								<Link
									className={
										item.soon ? "ib-index-book-item soon-text" : "ib-index-book-item "
									}
									key={index}
									to={
										item.soon
											? "#"
											: {
												pathname: `/index-book/${item.bookId}/${item.pageKey}`,
												state: {
													backPath: document.location.pathname,
												},
											}
									}
								>
									<div
										className={
											item.soon
												? "ib-index-book-item--img soon"
												: "ib-index-book-item--img"
										}
									>
										<SVG src={item.thumbnailsPage} />
									</div>

									<p className={item.soon ? "soon" : ""}>{item.name}</p>
								</Link>
							);
						})}
				</div>
				<div className="ib-index-book__right">
					{/** display 6 next items on the right of the props.page  */}
					{props.indexBook?.page
						.slice(9 + props.page.currentOffset, 15 + props.page.currentOffset)
						.map((item: any, index: number) => {
							return (
								<Link
									to={
										item.soon
											? "#"
											: {
												pathname: `/index-book/${item.bookId}/${item.pageKey}`,
												state: {
													backPath: document.location.pathname,
												},
											}
									}
									className={
										item.soon ? "ib-index-book-item soon-text" : "ib-index-book-item "
									}
									key={index}
								>
									<div
										className={
											item.soon
												? "ib-index-book-item--img soon"
												: "ib-index-book-item--img"
										}
									>
										<SVG src={item.thumbnailsPage} />
									</div>
									<p className={item.soon ? "soon" : ""}>{item.name}</p>
								</Link>
							);
						})}
				</div>

				<div className="ib-index-book__btn-container">
					<Button
						className={!hasPreviousPage() ? "blue disabled" : "blue "}
						disabled={!hasPreviousPage()}
						onClick={() => {
							changePage("prev");
						}}
					>
						Precedent
					</Button>
					<Button
						className={!hasNextPage() ? "blue disabled" : "blue "}
						disabled={!hasNextPage()}
						onClick={() => {
							changePage("next");
						}}
					>
						Suivant
					</Button>
					<Button className="dark">
						<Link
							style={{ color: "white", display: "flex", alignItems: "center" }}
							to="/"
						>
							<TiHome className="home-icon" /> Accueil
						</Link>
					</Button>
				</div>
			</div>
		</>
	);
};

export default IndexBookV2;
