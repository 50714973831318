import { Storage } from 'aws-amplify';

const responseMap: any = {};

export default class FileDS {

  private static getUrlFile = (key: string, callback: Function) => {
    callback(`${process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL}${key}`);
  };

  static getFileUrl(key: string, callback: Function) {
    if (process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL) {
      FileDS.getUrlFile(key, callback);
    } else {
      FileDS.getSecuredUrlFile(key, (url: string) => {
        callback(url);
      });
    }
  }

  static getSecuredUrlFile(key: string, callback: Function, size?: string | number) {
    Storage.get(key, {
      customPrefix: {
        public: ''
      },
    }).then((url: any) => {
      callback(url)
    });
  }

  static downloadFile(key: string, callback: Function, size?: string | number) {
    if (responseMap[key]) {
      console.log('from cache => ' + key);
      callback(responseMap[key]);
    } else {
      console.log('Not from cache => ' + key);
      Storage.get(key, {
        customPrefix: {
          public: ''
        },
        download: true
      }).then((response: any) => {
        responseMap[key] = response;
        callback(response);
      });
    }
  }

}

