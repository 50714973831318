import "./App.scss";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AudioPlayerContextProvider from "./components/utils/AudioPlayerContext";
import BookPageComponent from "./components/BookPage";
import HomepageComponent from "./components/Homepage";
import IndexBookComponent from "./components/IndexBook";
import Secured from "Secured";
import ThemeContextProvider from "./components/utils/ThemeContext";
import UserContextProvider from "components/utils/UserContext";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import BlockerContextProvider from "components/utils/BlockerContext";
import UserAnnouncement from "components/elements/common/phoneblocked/UserAnnouncement";
import MainContextProvider from "components/utils/MainContext";

const signUpFields = [
	{
		type: "name",
		label: "Name",
		placeholder: "Enter your name",
		hint: null,
		required: true,
	},
	{
		type: "email",
		label: "Email Address *",
		required: true,
	},
	{
		type: "password",
		label: "Password *",
		required: true,
	},
];

function App() {
	return (
		<>
			<UserAnnouncement />
			<UserContextProvider>
				<BlockerContextProvider>
					<MainContextProvider>
						<Secured>
							<Router>
								<Switch>
									<Route path="/" exact render={Homepage} />
									<Route path="/index-book/:bookId" exact render={IndexBook} />
									<Route path="/index-book/:bookId/:pageNumber" exact render={BookPage} />
									{process.env.REACT_APP_DOMAIN_NAME === 'localhost' && (
										<Route path="/login" exact render={Login} />
									)}
								</Switch>
							</Router>
						</Secured>
					</MainContextProvider>
				</BlockerContextProvider>
			</UserContextProvider>
		</>
	);
}

export default App;

const Homepage = (props: any) => <HomepageComponent {...props} />;

const IndexBook = (props: any) => (
	<ThemeContextProvider>
		<IndexBookComponent {...props} />
	</ThemeContextProvider>
);

const BookPage = (props: any) => (
	<ThemeContextProvider>
		<AudioPlayerContextProvider>
			<BookPageComponent {...props} />
		</AudioPlayerContextProvider>
	</ThemeContextProvider>
);

const Login = () => (
	<AmplifyAuthenticator usernameAlias="email">
		<AmplifySignUp
			usernameAlias="email"
			formFields={signUpFields}
			slot="sign-up"
		/>
	</AmplifyAuthenticator>
);

